<template>
  <v-card
    color="#F4F9FF"
    style="display:flex; flex-direction: column; height: 100%; width: 100%; overflow-y: auto"
  >
    <v-layout style="display: flex; flex-direction: column;">
      <label class="item-title">Account information</label>
      <label class="email-title ml-10 mb-5"
        >Update and verify the admin information</label
      >
      <editable-avatar
        class="ma-6"
        :image="profile.image"
        :onImageSelected="onImageSelected"
      />
      <v-row class="px-6">
        <v-col class="px-3" sm="6" cols="12">
          <v-text-field
            v-model="profile.first_name"
            label="First Name"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
          />
        </v-col>
        <v-col class="px-3" sm="6" cols="12">
          <v-text-field
            v-model="profile.last_name"
            label="Last Name"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
          />
        </v-col>
        <v-col class="px-3" sm="6" cols="12">
          <v-text-field
            v-model="profile.email"
            label="Email Address"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
          />
        </v-col>
        <v-col class="px-3" sm="6" cols="12">
          <v-text-field
            v-model="profile.phone"
            label="Phone"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
          />
        </v-col>
        <v-col class="px-3" sm="6" cols="12">
          <v-text-field
            v-model="profile.address"
            label="Address"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
          />
        </v-col>
        <v-col class="px-3" sm="6" cols="12">
          <v-text-field
            v-model="profile.address2"
            label="Address 2"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
          />
        </v-col>
      </v-row>
      <v-row
        class="mb-10"
        style="display:flex; justify-content:flex-end; flex-direction: row;"
      >
        <v-btn
          rounded
          width="200"
          color="primary"
          class="mb-3 mr-10"
          @click="onUpdate()"
          :loading="loading"
          v-text="'Save'"
        />
      </v-row>
    </v-layout>
    <v-divider class="mb-5" />
    <v-layout style="display: flex; flex-direction: column;">
      <div
        class="d-flex"
        style="justify-content: space-between; align-items: center;"
      >
        <label class="item-title">Other accounts</label>
        <v-btn
          rounded
          small
          width="200"
          color="primary"
          class="mb-3 mr-10"
          @click="onAddAdmin"
          :loading="loading"
        >
          <v-icon>mdi-plus</v-icon>Add Admin
        </v-btn>
      </div>
      <label class="email-title ml-10 mb-5"
        >Add and update the admin accounts</label
      >
      <v-data-table :items="admins" :headers="headers" class="mx-5 mb-8">
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar class="elevation-6 my-3 ml-5">
            <v-img :src="item.image ? item.image : placeholderImage" />
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div>{{ item.first_name }} {{ item.last_name }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div>
            <v-btn icon @click="onEditAdmin(item)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn icon @click="onDeleteAdmin(item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-layout>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <confirm-delete-dialog
      :deleteDialog="deleteConfirmDialg"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
    <admin-account-dialog
      :dialog="adminDialog"
      :onCloseDialog="onCloseAdminDialog"
      :onSaved="onSavedAdmin"
      :item="selected"
      v-if="adminDialog"
    />
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "../../components/core/EditableAvatar.vue";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog.vue";
import AdminAccountDialog from "../../components/admin/AdminAccountDialog.vue";
export default {
  components: { EditableAvatar, ConfirmDeleteDialog, AdminAccountDialog },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      showPassword: false,
      statusItems: ["Available", "Pending", "Disabled"],
      selectedImage: null,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      profile: {},
      deleteConfirmDialg: false,
      admins: [],
      headers: [
        { text: "", value: "image" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions" },
      ],
      adminDialog: false,
      deleteAdminDialog: false,
      selected: null,
      placeholderImage: require("@/assets/default.jpg"),
    };
  },
  methods: {
    ...mapActions("auth", {
      fetchProfile: "fetchProfile",
      updateAdmin: "updateAdmin",
      fetchAllAdmin: "fetchAllAdmin",
      addAdminAccount: "addAdminAccount",
      updateAdminAccount: "updateAdminAccount",
      deleteAdminAccount: "deleteAdminAccount",
    }),
    onImageSelected(image) {
      this.selectedImage = image;
    },
    onUpdate() {
      var formData = new FormData();
      // Object.keys(this.profile).map((key) => {
      //   if (this.profile[key] != undefined)
      //     formData.append(key, this.profile[key]);
      // });
      if (this.profile.first_name != undefined)
        formData.append("first_name", this.profile.first_name);
      if (this.profile.first_name != undefined)
        formData.append("last_name", this.profile.last_name);
      if (this.profile.email != undefined)
        formData.append("email", this.profile.email);
      if (this.profile.phone != undefined)
        formData.append("phone", this.profile.phone);
      if (this.profile.address != undefined)
        formData.append("address", this.profile.address);
      if (this.profile.address2 != undefined)
        formData.append("address2", this.profile.address2);
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.loading = true;
      this.updateAdmin(formData)
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onCloseConfirmDelete() {
      this.deleteConfirmDialg = false;
    },
    onConfirmDelete() {
      this.deleteConfirmDialg = false;
      this.loading = true;
      this.deleteAdminAccount({ _id: this.selected._id })
        .then((res) => {
          this.loading = false;
          this.admins = this.admins.filter((a) => a._id != res._id);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    onDeleteAdmin(item) {
      this.selected = item;
      this.deleteConfirmDialg = true;
    },
    onAddAdmin() {
      this.adminDialog = true;
    },
    onEditAdmin(admin) {
      this.selected = admin;
      this.adminDialog = true;
    },
    onCloseAdminDialog() {
      this.adminDialog = false;
      this.selected = null;
    },
    onSavedAdmin(admin) {
      this.adminDialog = false;
      var saved = false;
      this.selected = null;
      admin.password = "";
      this.admins = this.admins.map((a) => {
        if (a._id == admin._id) {
          saved = true;
          return admin;
        } else {
          return a;
        }
      });
      if (!saved) {
        this.admins.push(admin);
      }
    },
  },
  created() {
    this.loading = true;
    this.fetchProfile()
      .then((data) => {
        this.loading = false;
        this.profile = { ...data };
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
    this.fetchAllAdmin()
      .then((res) => {
        this.admins = res;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>

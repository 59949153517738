<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline"
            >{{ admin._id ? "Edit" : "Add" }} admin account</span
          >
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="align-items: center; display: flex; flex-direction: column;"
        >
          <editable-avatar
            :onImageSelected="onImageSelected"
            :image="admin.image"
          />
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="admin.first_name"
                  label="First Name"
                  type="name"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="admin.last_name"
                  label="Last Name"
                  type="name"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="admin.email"
                  label="Email*"
                  type="email"
                  @onChange="onChange"
                  :rules="[rules.required, rules.email]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="admin.phone"
                  label="Phone*"
                  type="phone"
                  @onChange="onChange"
                  :rules="[rules.required, rules.phone]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="admin.password"
                  label="Password*"
                  type="password"
                  @onChange="onChange"
                  :rules="[rules.required, rules.min]"
                  required
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <vuetify-google-autocomplete
                  id="map"
                  placeholder="Address"
                  v-model="admin.address"
                  v-on:placechanged="getAddressData"
                  ref="address"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="admin.address2"
                  label="Address 2"
                  type="address"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="admin.city"
                  label="City"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="admin.state"
                  label="State"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="admin.zip"
                  label="Zip"
                  type="text"
                  @onChange="onChange"
                  required
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text class="pt-0 pl-9">
          <small>*indicates required field</small>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
            {{ admin._id ? "Save" : "Add" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import { mapActions } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";

export default {
  components: {
    VuetifyGoogleAutocomplete,
    EditableAvatar,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onCloseDialog: {
      type: Function,
    },
    item: Object,
    onSaved: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      statusOptions: ["Yes", "No", "Urgent Help", "No response"],
      admin: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        address2: "",
        password: "",
        image: null,
      },
      error: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      addAdminAccount: "auth/addAdminAccount",
      updateAdminAccount: "auth/updateAdminAccount",
    }),
    onSave() {
      this.loading = true;
      var formData = new FormData();
      Object.keys(this.admin).map((key) => {
        if (this.admin[key]) formData.append(key, this.admin[key]);
      });

      if (!this.admin._id) {
        this.addAdminAccount(formData)
          .then((res) => {
            this.loading = false;
            this.error = null;
            this.onSaved(res);
          })
          .catch((error) => {
            if (error.respnose) {
              console.log(error.response.data.message);
              this.loading = false;
              this.error = error.response.data.message;
            } else {
              console.log(error);
            }
          });
      } else {
        this.updateAdminAccount(formData)
          .then((res) => {
            this.loading = false;
            this.error = null;
            this.onSaved(res);
          })
          .catch((error) => {
            if (error.respnose) {
              console.log(error.response.data.message);
              this.loading = false;
              this.error = error.response.data.message;
            } else {
              console.log(error);
            }
          });
      }
    },
    onImageSelected(image) {
      this.admin.image = image;
    },
    getAddressData(addressData) {
      this.admin.address = addressData.name;
      this.admin.state = addressData.administrative_area_level_1;
      this.admin.city = addressData.locality;
      this.admin.zip = addressData.postal_code;
      this.admin.location = addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.admin.address);
    },
    onClose() {
      this.admin = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        address2: "",
        password: "",
        image: null,
      };
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
  },
  mounted() {
    if (this.item) {
      this.admin = this.item;
    }
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
